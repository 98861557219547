import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import { device } from "../device"

const CvPage = () => {
  return (
    <Layout>
      <SEO title="About" />
      <CVContainer>
        <TextBaseContainer>
          <TextSection>
            <H>持塚 三樹</H>
            <HistoryItem>
              <dt>1974</dt>
              <dd>静岡県生まれ。現在も静岡県にて制作活動を行う。</dd>
            </HistoryItem>
            <HistoryItem>
              <dt>1999</dt>
              <dd>常葉学園短期大学菊川校舎.美術デザイン科 卒業。</dd>
            </HistoryItem>
            <HS>個展</HS>
            <HistoryItem>
              <dt>2005</dt>
              <div>
                <dd>my only star（Gallery unseal／東京）</dd>
                <dd>CYCLE（ecoms fit／静岡）</dd>
                <dd>Forest of doze （art age gallery／愛知）</dd>
              </div>
            </HistoryItem>
            <HistoryItem>
              <dt>2007</dt>
              <dd>イト（Misako & Rosen／東京）</dd>
            </HistoryItem>
            <HistoryItem>
              <dt>2009</dt>
              <dd>キ（Misako & Rosen／東京）</dd>
            </HistoryItem>
            <HistoryItem>
              <dt>2010</dt>
              <dd>SLS（Misako & Rosen／東京）</dd>
            </HistoryItem>
            <HistoryItem>
              <dt>2012-13</dt>
              <dd>Sun Day（ヴァンジ彫刻庭園美術館／静岡）</dd>
            </HistoryItem>
            <HistoryItem>
              <dt>2014</dt>
              <dd>Quadrangle(Misako & Rosen／東京）</dd>
            </HistoryItem>
            <HS>主なグループ展</HS>
            <HistoryItem>
              <dt>2008</dt>
              <dd>
                ニュートーキョーコンテンポラリーズ（新丸の内ビルディング7F／東京）
              </dd>
            </HistoryItem>
            <HistoryItem>
              <dt>2009</dt>
              <div>
                <dd>
                  ゲバゲバサマーショー～ゲバゲバな４週間～（Misako &
                  Rosen／東京）
                </dd>
                <dd>
                  Stripe, Dots and Skulls In Assorted Shapes, Colors and
                  Sizes（Soka Art Center Tainan／台湾)
                </dd>
                <dd>Pink（ファーム　ヒロミヨシイ／東京）</dd>
              </div>
            </HistoryItem>
            <HistoryItem>
              <dt>2010</dt>
              <dd>作家のアトリエ（ベルナール・ビュフェ美術館／静岡）</dd>
            </HistoryItem>
            <HistoryItem>
              <dt>2012</dt>
              <dd>
                「日独交流150周年静岡・ブレーメン国際交流プロジェクトDECWAS」（ブレーメン／ドイツ）
              </dd>
            </HistoryItem>
            <HistoryItem>
              <dt>2013</dt>
              <dd>Around the sun（SUNDAY／東京）</dd>
            </HistoryItem>
          </TextSection>
          {/* en */}
          <TextSection>
            <H>Miki Mochizuka</H>
            <HistoryItem>
              <dt>1974</dt>
              <dd>born in Shizuoka</dd>
            </HistoryItem>
            <HistoryItem>
              <dt>1999</dt>
              <dd>Tokoha Gakuen Junior Collage, department of design BA</dd>
            </HistoryItem>
            <HS>Solo Exhibition</HS>
            <HistoryItem>
              <dt>2005</dt>
              <div>
                <dd>my only star（Gallery unseal／Tokyo）</dd>
                <dd>CYCLE（ecoms fit／Shizuoka）</dd>
                <dd>Forest of doze （art age gallery／Aichi）</dd>
              </div>
            </HistoryItem>
            <HistoryItem>
              <dt>2007</dt>
              <dd>Ito（Misako & Rosen／Tokyo）</dd>
            </HistoryItem>
            <HistoryItem>
              <dt>2009</dt>
              <dd>Ki（Misako & Rosen／Tokyo）</dd>
            </HistoryItem>
            <HistoryItem>
              <dt>2010</dt>
              <dd>SLS（Misako & Rosen／Tokyo）</dd>
            </HistoryItem>
            <HistoryItem>
              <dt>2012-13</dt>
              <dd>Sun Day（The Vangi Sculpture Garden Museum／Shizuoka）</dd>
            </HistoryItem>
            <HistoryItem>
              <dt>2014</dt>
              <dd>Quadrangle(Misako & Rosen／Tokyo）</dd>
            </HistoryItem>
            <HS>Group Exhibition</HS>
            <HistoryItem>
              <dt>2008</dt>
              <dd>
                New Tokyo Contemporaries（Shin-marunouchi building 7f,
                marunouchi HOUSE/Tokyo）
              </dd>
            </HistoryItem>
            <HistoryItem>
              <dt>2009</dt>
              <div>
                <dd>
                  Geba-geba summer show - geba-geba 4 weeks～ (Misako &
                  Rosen／Tokyo）
                </dd>
                <dd>
                  Stripe, Dots and Skulls In Assorted Shapes, Colors and
                  Sizes（Soka Art Center Tainan／Taiwan)
                </dd>
                <dd>Pink（FARM,hiromiyoshii／Tokyo）</dd>
              </div>
            </HistoryItem>
            <HistoryItem>
              <dt>2010</dt>
              <dd>Ateliers d' artistes（Musee Bernard Buffet ／Shizuoka）</dd>
            </HistoryItem>
            <HistoryItem>
              <dt>2012</dt>
              <dd>
                artist in residence in Bremen as part of DECWAS (150 Years of
                Japan/Germany Exchange）
              </dd>
            </HistoryItem>
            <HistoryItem>
              <dt>2013</dt>
              <dd>Around the sun（SUNDAY／Tokyo）</dd>
            </HistoryItem>
          </TextSection>
        </TextBaseContainer>
        <ContactSection>
          <div>
            <p
              css={css`
                font-weight: bold;
              `}
            >
              Contact.
            </p>
            <p>
              <span>作品に関するお問い合わせ</span>
              <a href="https://misakoandrosen.jp/contact/index.html">
                https://misakoandrosen.jp/contact/index.html
              </a>
            </p>
            <p>
              <span>ワークショップなどのお問い合わせ</span>
              <a href="mailto:mikimochizuka@gmail.com">
                mikimochizuka@gmail.com
              </a>
            </p>
          </div>
        </ContactSection>
      </CVContainer>
    </Layout>
  )
}

const TextBaseContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  font-size: 0.85rem;
  font-family: ${props => props.theme.fontFamily.roboto};

  @media ${device.mobile} {
    flex-direction: column;
  }
`

const CVContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ContactSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-family: ${props => props.theme.fontFamily.roboto};
  font-size: 0.85rem;
  line-height: 1.5;
  margin-top: 128px;
  align-items: flex-end;
  span {
    display: block;
  }
  p {
    margin-top: 8px;
  }
  a {
    font-family: ${props => props.theme.fontFamily.roboto};
    color: hsla(90, 80%, 20%, 0.5);
  }
`

const H = styled.h3`
  font-size: 1rem;
`

const HS = styled.h4`
  font-size: 0.85rem;
`

const TextSection = styled.section`
  flex-basis: 45%;
  padding: 0 16px;

  @media ${device.mobile} {
    flex-basis: 100%;
    padding: 0;
  }
`

const HistoryItem = styled.dl`
  display: flex;
  line-height: 1.8;
  margin: 0;
  dt {
    flex-basis: 5rem;
  }
  dd {
    flex-shrink: 1;
    margin: 0;
  }

  @media ${device.mobile} {
    flex-direction: column;
    margin-bottom: 4px;

    dt {
      flex-basis: 1rem;
      margin: 0;
      padding: 0;
    }
  }
`

export default CvPage
